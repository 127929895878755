<template>
  <div style="overflow: hidden;" v-show="start"  class="ppp">
    <div class="content_page4" style="overflow: hidden;">
      <div class="page3_title">
        <div class="left_title">Nos</div>
        <div class="white_circle">&</div>
        <div class="right_title">Partenaires</div>
      </div>
      <div class="partenaires" style="overflow: hidden;">
        <div class="col_service">
        <div class="swiper-container" style="min-height: 400px;">
          <div class="swiper-wrapper"  style="margin-bottom: 22px;"  >
          <swiper :loop="true" :modules="modules22" :slides-per-view="4" :autoplay="{delay:2000,stopOnLastSlide:false,reverseDirection:false}"  >
                <swiper-slide><img src="src/partenaires/Groupe237.png" alt=""></swiper-slide>
                <swiper-slide><img src="src/partenaires/Groupe239.png" alt=""></swiper-slide>
                <swiper-slide><img src="src/partenaires/Groupe242.png" alt=""></swiper-slide>
                <swiper-slide><img src="src/partenaires/Groupe245.png" alt=""></swiper-slide>
                <swiper-slide><img src="src/partenaires/Groupe283.png" alt=""></swiper-slide>
                <swiper-slide><img src="src/partenaires/Groupe289.png" alt=""></swiper-slide>
                <swiper-slide><img src="src/partenaires/Groupe292.png" alt=""></swiper-slide>
                <swiper-slide><img src="src/partenaires/Groupe247.png" alt=""></swiper-slide>

            </swiper>
          </div>    
          <div class="swiper-wrapper">
          <swiper  :loop="true" :slides-per-view="3"  :modules="modules22" :autoplay="{delay:2000,stopOnLastSlide:false,reverseDirection:true}"  >
                <swiper-slide><img src="src/partenaires/Groupe237.png" alt=""></swiper-slide>
                <swiper-slide><img src="src/partenaires/Groupe239.png" alt=""></swiper-slide>
                <swiper-slide><img src="src/partenaires/Groupe242.png" alt=""></swiper-slide>
                <swiper-slide><img src="src/partenaires/Groupe245.png" alt=""></swiper-slide>
                <swiper-slide><img src="src/partenaires/Groupe283.png" alt=""></swiper-slide>
                <swiper-slide><img src="src/partenaires/Groupe289.png" alt=""></swiper-slide>
                <swiper-slide><img src="src/partenaires/Groupe292.png" alt=""></swiper-slide>
                <swiper-slide><img src="src/partenaires/Groupe247.png" alt=""></swiper-slide>

            </swiper>
            
          </div>    
        </div>
        </div>
        <div class="brand_title11">ILS FONT CONFIANCE À iHospitality</div>
        
      </div>

        
      
    </div>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue';

import { Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';

// import 'vue3-carousel/dist/carousel.css';
// import { Carousel, Slide,Pagination,Navigation } from 'vue3-carousel';
export default {
  name: 'Page_4',
  components: {
    Swiper,
        SwiperSlide,
    // Carousel,
    // Slide,
    // Pagination,
    // Navigation,
  },
  data: () => ({
    slides :[1,2,3,4],
    start:false,
    modules22:[Autoplay]
  }),
  mounted(){
    setTimeout(()=>{
      this.start = true
    },300)
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .ppp{
  animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} */
.content_page4 .brand_page3_title{
  color: #fff;
  width: 100%;
  font-size: 100px;
  animation: content_page4_typing .5s steps(22), content_page4_blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  /* border-right: 3px solid; */
  margin-bottom: 6px;
  /* animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
}

.content_page4 .brand_page3_title1{
  color: #fff;
  width: 100%;
  font-size: 100px;
  
  animation: content_page4_typing 1s steps(22), content_page4_blink .5s step-end infinite alternate;
  white-space: nowrap;
  
  overflow: hidden;
  /* border-right: 3px solid; */
  margin-bottom: 6px;
}
.white_circle{
  background: #fff;
  color: #264750;
  padding: 12px;
  border-radius: 50px;
  width: 22px;
  height: 22px;
  text-align: center;
  transform: rotate(12deg);
  border: 3px solid #fff;
  position: relative;
  z-index: 9;
}
.page3_title{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
  font-weight: 600;
  font-size: 18px;
  /* animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
}
.right_title{
  background: #d85d36;
  color: #fff;
  padding: 12px;
  border-radius: 50px;
  width: 140px;
  text-align: center;
  transform: rotate(16deg);
  /* border: 1px solid #fff; */
  opacity: .8;
  position: relative;
  right: 3px;
  top: 12px;
}
.left_title{
  background: #264750;
  color: #fff;
  padding: 12px;
  border-radius: 50px;
  width: 90px;
  text-align: center;
  transform: rotate(-8deg);
  /* border: 1px solid #fff; */
  opacity: .8;
  position: relative;
  left: 2px;
}
.content_page4 p{
  color: #fff;
  font-size: 18px;
  margin: 0px;
  width: 70%;
  margin-top: 24px;
  
  text-align: center;
}
.content_page4{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.partenaires{
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.service{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  margin-bottom: 24px;
  transform: scale(.4);
  opacity: 0;
  animation: content_page4_AnimationBrand .5s ease ;
  animation-fill-mode: forwards;
}
.col_service{
  flex: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
}
.col_service1 img{
    width: 50%;
    /* animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */

}
.col_service1{
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service_img{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  background-color: #fff;
  justify-items: center;
  align-items: center;
  padding: 12px;
  margin-bottom: 12px;
}
.service img{
  width: 40px;
  position: relative;
  left: 10px;
}
.service h4{
  margin: 0px;
  font-size: 18px;
}
.service ul{
  list-style: none;
}
.service ul li{
  font-size: 14px;
}

.partenaires_mobile{
    display: none !important;
  }

@media only screen and (max-width: 600px) {
  .partenaires{
    display: none !important;
  }
  .service{
    width: calc(100% - 24px);
    padding: 12px;
  }
 
 



@keyframes myAnim {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(100px);
	}
}


@keyframes content_page4_typing {
  from {
    width: 0
    
  }
}
    
@keyframes content_page4_blink {
  50% {
    border-color: transparent
  }
}

@keyframes content_page4_AnimationBrand {
    0%{
      transform: scale(.4);
      opacity: 0;
    }
    
    100%{
      transform: scale(1);
      opacity: 1;
    }
}



@-webkit-keyframes slide-top {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide-top {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
}


.swiper-container{
  min-height: 400px;
  margin-top: 15%;
  max-width: 100%;
}
.swiper-slide{
 text-align: center;
}

.brand_title11{
  color: #fff;
  width: 100%;
  font-size: 2em;
  text-align: center;
  animation: content_page4_typing 1s steps(22), content_page4_blink 2.5s step-end infinite alternate;
  white-space: nowrap;
  margin: auto;
  text-transform: uppercase;
  overflow: hidden;
  /* border-right: 3px solid; */
  margin-bottom: 6px;
}

@keyframes content_page4_typing {
  from {
    width: 0
    
  }
}
    
@keyframes content_page4_blink {
  50% {
    border-color: transparent
  }
}

@keyframes content_page4_AnimationBrand {
    0%{
      transform: scale(.4);
      opacity: 0;
    }
    
    100%{
      transform: scale(1);
      opacity: 1;
    }
}



@-webkit-keyframes slide-top {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide-top {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


</style>
