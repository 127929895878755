<template>
  <div>
    <div class="content_page5">
      <div class="left_page1">
        <div class="brand_title">BOOKING - RESERVATIONS</div>
      <!-- <div class="brand_title1">ORDER & PAY</div> -->
      <p class="zoom-in">Gestion de plusieurs points de ventes (RVC) ✅
        <br>
        <br>
        Personnalisation et gestion du plan de table ✅
        <br>
        <br>
        Gestion du room service ✅
        <br>
        <br>
        Synchronisation de tous les TMS ✅
      </p>
      </div>
      <div class="right_page1">
        <div ref="animationContainer"  style="
    width: 70%;
" ></div>
      </div>
      
    </div>
  </div>
</template>



<script>



import lottie from 'lottie-web'; // Importez la bibliothèque lottie-web

export default {


  name: 'Page_5',
  components: {},


  data: () => ({


  }),

  props: {
    title: String
  },

  mounted() {
    
    // Chargez et affichez l'animation Lottie
    lottie.loadAnimation({
      container: this.$refs.animationContainer, // Référence à l'élément DOM où l'animation sera affichée
      renderer: 'svg', // Vous pouvez également utiliser 'canvas' si vous le préférez
      loop: true, // Spécifie si l'animation doit boucler
      autoplay: true, // Démarre automatiquement l'animation lorsqu'elle est chargée
      path: '/src/animations/booking.json' // Chemin vers votre fichier JSON Lottie
    });
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.content_page5 .brand_title{
  color: #fff;
  width: 100%;
  font-size: 3em;
  animation: content_page5_typing .5s steps(22), content_page5_blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  /* border-right: 3px solid; */
  margin-bottom: 6px;
}

.content_page5 .brand_title1{
  color: #fff;
  width: 100%;
  font-size: 3em;
  
  animation: content_page5_typing 1s steps(22), content_page5_blink .5s step-end infinite alternate;
  white-space: nowrap;
  
  overflow: hidden;
  /* border-right: 3px solid; */
  margin-bottom: 6px;
}


.content_page5 p{
  color: #fff;
  font-size: 20px;
  margin: 0px;
  width: 70%;
  margin-top: 24px;
}
.content_page5{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content_page5 .left_page1,.content_page5 .right_page1{
  flex: 1;
}
.content_page5 .left_page1{
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 8%;
 
}
.content_page5 .right_page1{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  overflow: hidden;
  
}
.content_page5 .brand{
  width: 70%;
  transform: rotate(-20deg);
  position: relative;
  left: 100%;
  animation: content_page5_AnimationBrand 1.5s ease ;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes content_page5_typing {
  from {
    width: 0
    
  }
}
    
@keyframes content_page5_blink {
  50% {
    border-color: transparent
  }
}

@keyframes content_page5_AnimationBrand {
    0%{
      left: 100%;
      transform: rotate(20deg);
      opacity: 0;
    }
    
    100%{
      left: 0%;
      transform: rotate(-20deg);
      opacity: 1;
    }
}

.right_page1 {
  margin-right: 15em;
}

/* Responsive */

@media only screen and (max-width: 600px) {
  .content_page5{
    overflow: hidden;
  }
  .content_page5 .brand_title,.content_page5 .brand_title1{
    font-size: 28px;
    font-weight: bold;
  }
  .content_page5 p{
    font-size: 12px;
  }
  .content_page5 .right_page1{
    overflow: visible;
    
  }
  .content_page5 .brand{
  width: 100%;
  transform: rotate(-20deg);
  position: relative;
  left: 100%;
  animation: content_page5_AnimationBrand 1.5s ease ;
  animation-fill-mode: forwards;
  opacity: 0;
}
}
/* End Responsive  */

.zoom-in {
  font-size: 20px; /* Taille initiale du texte */
  animation: zoomIn 0.9s ease; /* Utilisation de l'animation "zoomIn" pendant 2 secondes */
}

@keyframes zoomIn {
  from {
    font-size: 1px; /* Taille initiale du texte au chargement */
  }
  to {
    font-size: 20px; /* Taille finale du texte après l'animation */
  }
}


</style>
