<template>
  <div>
    <div class="content_page8">
      <div class="left_page1">
        <div class="brand_title">Contactez-Nous</div>

        <div class="formulaire" style="margin-top: 48px; width: 85%;">
          <label for="nom" style="color: white">Nom :</label>
          <input type="text" id="nom" v-model="contact.nom" name="nom" class="transparent-input" required>
          <br>
          <br>
          <br>
          <label for="prénom" style="color: white">Prénom :</label>
          <input type="text" id="prenom" v-model="contact.prenom" name="prenom" class="transparent-input" required>
          <br>
          <br>
          <br>
          <label for="email" style="color: white">Email :</label>
          <input type="email" id="email" v-model="contact.email" name="email" class="transparent-input" required>
          <br>
          <br>
          <br>
          <!-- <label for="telephone" style="color: white">Téléphone :</label>
          <input type="tel" id="telephone" name="telephone" class="transparent-input">
          <br>
          <br>
          <br> -->
          <label for="message" style="color: white">Message :</label> 
          <textarea id="message" name="message" v-model="contact.message" class="transparent-input" rows="4" required></textarea>
          <br>
          <br>
          <br>
          <button type="submit" class="button" @click="submitForm" style="background-color: transparent;">
            <span class="top-left"></span>
            <span class="top-right"></span>
            <span class="bottom-left"></span>
            <span class="bottom-right"></span>
            <div>Envoyer</div>
          </button>
        

        </div>

      </div>
      <div class="right_page1">
        <div ref="animationContainer" v-show="!formSubmitted" style="width: 100%;"></div>
        <div ref="animationContainer2"  v-show="formSubmitted" style="width: 100%;"></div>
      </div>

    </div>
  </div>
</template>




<script>
import lottie from 'lottie-web'; // Importer la bibliothèque lottie-web
import axios  from "axios"; // Importer la bibliothèque axios

export default {
  name: 'Page_8',
  components: {},

  data() {
    return {
        contact: {
            nom: '',
            prenom: '',
            email: '',
            message: ''
        },
        formSubmitted: false
    };
},

  props: {
    title: String,
    navigate:  Function
  },
  methods:{
   submitForm(){

    var queryString = Object.keys(this.contact).map(key => key + '=' + this.contact[key]).join('&');

      axios.get("https://myspacedev.ihospitality.fr/public/ticket/wassim.php?"+queryString).then((res)=>{
        console.log({res})
        
        lottie.loadAnimation({
          container: this.$refs.animationContainer2, // Référence à l'élément DOM où l'animation sera affichée
          renderer: 'svg', // Vous pouvez également utiliser 'canvas' si vous le préférez
          loop: true, // Spécifie si l'animation doit boucler
          autoplay: true, // Démarre automatiquement l'animation lorsqu'elle est chargée
          path: '/src/animations/mailsent.json' // Chemin vers votre fichier JSON Lottie
        });
        this.formSubmitted=true;
        // Réinitialise les valeurs du formulaire
        this.contact = {
          nom: '',
            prenom: '',
            email: '',
            message: ''
        }
      })
      .catch((err)=>{
        console.log({err})

        lottie.loadAnimation({
          container: this.$refs.animationContainer2, // Référence à l'élément DOM où l'animation sera affichée
          renderer: 'svg', // Vous pouvez également utiliser 'canvas' si vous le préférez
          loop: true, // Spécifie si l'animation doit boucler
          autoplay: true, // Démarre automatiquement l'animation lorsqu'elle est chargée
          path: '/src/animations/mailsent.json' // Chemin vers votre fichier JSON Lottie
        });
        this.formSubmitted=true;
        setTimeout(() => {
          this.formSubmitted=false;
        }, 1500);
        // Réinitialise les valeurs du formulaire
        this.contact = {
          nom: '',
            prenom: '',
            email: '',
            message: ''
        }
      })
   } 
  },
  mounted() {
    // Chargez et affichez l'animation Lottie
    lottie.loadAnimation({
      container: this.$refs.animationContainer, // Référence à l'élément DOM où l'animation sera affichée
      renderer: 'svg', // Vous pouvez également utiliser 'canvas' si vous le préférez
      loop: true, // Spécifie si l'animation doit boucler
      autoplay: true, // Démarre automatiquement l'animation lorsqu'elle est chargée
      path: '/src/animations/contactus.json' // Chemin vers votre fichier JSON Lottie
    });
  }
}
</script>


<!-- CSS Bouton Envoyer -->

<style>
.button .top-left,
.button .top-right {
  top: -2px;
  border-top: 1px solid hsla(0, 0%, 100%, .3);
}

.button .bottom-left,
.button .bottom-right {
  bottom: -2px;
  border-bottom: 1px solid hsla(0, 0%, 100%, .3);
}

.button .bottom-left {
  left: -2px;
  border-left: 1px solid hsla(0, 0%, 100%, .3);
}

.button .top-left {
  left: -2px;
  border-left: 1px solid hsla(0, 0%, 100%, .3);
}

.button .bottom-right {
  right: -2px;
  border-right: 1px solid hsla(0, 0%, 100%, .3);
}

.button .top-right {
  right: -2px;
  border-right: 1px solid hsla(0, 0%, 100%, .3);
}

.button span {
  position: absolute;
  height: 10px;
  width: 10px;
  -webkit-transition: width .5s cubic-bezier(.16, .43, .42, .84), height .5s cubic-bezier(.16, .43, .42, .84);
  transition: width .5s cubic-bezier(.16, .43, .42, .84), height .5s cubic-bezier(.16, .43, .42, .84);
}

.button {
  -webkit-animation: button 1s ease-in-out;
  animation: button 1s ease-in-out;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
  bottom: 1px !important;
  left: 1px;
  opacity: 1;
  cursor: pointer;
  width: 9vw;
  height: 2.5vw;
  border: 1px solid hsla(0, 0%, 100%, .1);
  color: #ffffff90;
  margin: auto;

}

.button:hover {
  color: #fff;
}

.button:hover span {
  width: 100%;
  height: 100%;
  border-color: #fff;
  -webkit-transition: width 1s cubic-bezier(.16, .43, .42, .84), height 1s cubic-bezier(.16, .43, .42, .84);
  transition: width 1s cubic-bezier(.16, .43, .42, .84), height 1s cubic-bezier(.16, .43, .42, .84);
}

/* FIN CSS BOUTON ENVOYER */
</style>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content_page8 .brand_title {
  color: #fff;
  width: 100%;
  font-size: 3em;
  animation: content_page8_typing .5s steps(22), content_page8_blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  /* border-right: 3px solid; */
  margin-bottom: 6px;
}

.content_page8 .brand_title1 {
  color: #fff;
  width: 100%;
  font-size: 3em;

  animation: content_page8_typing 1s steps(22), content_page8_blink .5s step-end infinite alternate;
  white-space: nowrap;

  overflow: hidden;
  /* border-right: 3px solid; */
  margin-bottom: 6px;
}


.content_page8 p {
  color: #fff;
  font-size: 20px;
  margin: 0px;
  width: 97%;
  margin-top: 24px;
}

.content_page8 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content_page8 .left_page1,
.content_page8 .right_page1 {
  flex: 1;
}

.content_page8 .left_page1 {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 8%;
  margin-left: 92px;
}

.content_page8 .right_page1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  overflow: hidden;
  /* scale: 1.4; */
}

.content_page8 .brand {
  width: 70%;
  transform: rotate(-20deg);
  position: relative;
  left: 100%;
  animation: content_page8_AnimationBrand 4s ease;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes content_page8_typing {
  from {
    width: 0
  }
}

@keyframes content_page8_blink {
  50% {
    border-color: transparent
  }
}

.right_page1 {
  margin-right: 10em;
}





/* End Responsive  */

.formulaire {
  font-size: 16px;
  /* Taille initiale du texte */
  animation: zoomIn 0.6s ease;
  /* Utilisation de l'animation "zoomIn" pendant 2 secondes */

}

@keyframes zoomIn {
  from {
    font-size: 1px;
    /* Taille initiale du texte au chargement */
  }

  to {
    font-size: 16px;
    /* Taille finale du texte après l'animation */
  }
}

/* CSS Formulaire */

.transparent-input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #ddd;
  margin-bottom: 12px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #333;
}

.transparent-input:focus {
  outline: none;
  border-bottom: 1px solid #555;
  /* Changer la couleur de la bordure inférieure en focus */
}
</style>
