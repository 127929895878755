<template>
  <div>
    <div class="content_page2">
      <div class="right_page2">
        <video class="brand_page2" controls poster="img/borne.png">
        <source src="img/video.mp4" type="video/mp4">
        
        Votre navigateur ne supporte pas la lecture de cette vidéo.
    </video>
        
      </div>
      <div class="left_page2">
        <div class="brand_page2_title">QUI SOMMES-NOUS ? {{ title }}</div>
      
      <p>Nous sommes une équipe dévouée à l'innovation et à l'excellence mais une vidéo vaut mille mots !</p>
      </div>
      
      
    </div>
  </div>
</template>

<script>


export default {
  name: 'Page_2',
  components:{
    
  },
  data: () => ({
  
  }),
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.content_page2 .brand_page2_title{
  color: #fff;
  width: 100%;
  font-size: 3em;
  animation: content_page2_typing .5s steps(22), content_page2_blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  /* border-right: 3px solid; */
  margin-bottom: 6px;
}

.content_page2 .brand_page2_title1{
  color: #fff;
  width: 100%;
  font-size: 3em;
  
  animation: content_page2_typing 1s steps(22), content_page2_blink .5s step-end infinite alternate;
  white-space: nowrap;
  
  overflow: hidden;
  /* border-right: 3px solid; */
  margin-bottom: 6px;
}


.content_page2 p{
  color: #fff;
  font-size: 18px;
  margin: 0px;
  width: 70%;
  margin-top: 24px;
}
.content_page2{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content_page2 .left_page2,.content_page2 .right_page2{
  flex: 1;
}
.content_page2 .left_page2{
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 8%;
 
}
.content_page2 .right_page2{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  overflow: hidden;
  
}
.content_page2 .brand_page2{
  width: 70%;
  position: relative;
  left: 100%;
  animation: content_page2_AnimationBrand 1.5s ease ;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes content_page2_typing {
  from {
    width: 0
    
  }
}
    
@keyframes content_page2_blink {
  50% {
    border-color: transparent
  }
}

@keyframes content_page2_AnimationBrand {
    0%{
      left: 100%;
      opacity: 0;
    }
    
    100%{
      left: 0%;
      opacity: 1;
    }
}

@keyframes content_page2_AnimationBrandMobile {
    0%{
      left: 100%;
      opacity: 0;
    }
    
    100%{
      left: 2%;
      opacity: 1;
    }
}


/* Responsive */

@media only screen and (max-width: 600px) {
  .content_page2 .brand_page2_title,.content_page2 .brand_page2_title1{
    font-size: 28px;
    font-weight: bold;
  }
  .content_page2 p{
    font-size: 12px;
  }
  .content_page2 .right_page2{
    overflow: visible;
    
  }
  .content_page2 .brand_page2{
  width: 120%;
  transform: rotate(59deg);
  position: relative;
  left: 100%;
  animation: content_page2_AnimationBrandMobile 1.5s ease ;
  animation-fill-mode: forwards;
  opacity: 0;
}
}


</style>
