<template>
  <div style="overflow: hidden;">
    <div class="content_page3" style="overflow: hidden;">
      <div class="page3_title">
        <div class="left_title">Nos</div>
        <div class="white_circle">&</div>
        <div class="right_title">Services</div>
      </div>
      <div class="services" style="overflow: hidden;">
        <div class="col_service">
          <div class="service">
          <div class="service_img"><img src="/img/avantages/x1/groupe186.png" alt="" class=""></div>
          <h4>Menus/Commandes</h4>
          <ul>
            <li>Personnalisation des menus</li>
            <li>Contrôle des commandes</li>
            <li>Personnalisation des QR Codes</li>
          </ul>
        </div>
        <div class="service">
          <div class="service_img"><img src="/img/avantages/x1/groupe217.png" alt="" class=""></div>
          <h4>Paiements</h4>
          <ul>
            <li>Gestion des moyens de paiement</li>
            <li>Sécurisation des transactions avecun code pin</li>
          </ul>
        </div>
        </div>

        <div class="col_service1">
          <img src="/img/grpmsq.0fd07d19.png" alt="" class="">
        </div>


        <div class="col_service">
          <div class="service">
          <div class="service_img"><img src="/img/avantages/x1/groupe182.png" alt="" class=""></div>
          <h4>Réservations</h4>
          <ul>
            <li>Gestion de plusieurs points de ventes (RVC)</li>
            <li>Personnalisation et gestion du plan de table</li>
            <li>Gestion du room service</li>
            <li>Synchronisation de tous les TMS</li>
          </ul>
        </div>
        
        <div class="service">
          <div class="service_img"><img src="/img/avantages/x1/groupe231.png" alt="" class=""></div>
          <h4>Outils adaptés</h4>
          <ul>
            <li>Rapport d'activité personnalisable et ajustable</li>
            <li>Gestion du budget prévisionnel</li>
          </ul>
        </div>
        </div>
        
        
      </div>

      <div class="services_mobile">

        <carousel  :items-to-show="1">
          <slide  v-for="slide in slides" :key="slide">
            <div class="service" v-if="slide==1">
                <div class="service_img"><img src="/img/avantages/x1/groupe186.png" alt="" class=""></div>
                <h4>Menus/Commandes</h4>
                <ul>
                  <li>Personnalisation des menus</li>
                  <li>Contrôle des commandes</li>
                  <li>Personnalisation des QR Codes</li>
                </ul>
              </div> 
              <div class="service" v-if="slide==2">
          <div class="service_img"><img src="/img/avantages/x1/groupe217.png" alt="" class=""></div>
          <h4>Paiements</h4>
          <ul>
            <li>Gestion des moyens de paiement</li>
            <li>Sécurisation des transactions avecun code pin</li>
          </ul>
        </div> 
        <div class="service" v-if="slide==3">
          <div class="service_img"><img src="/img/avantages/x1/groupe182.png" alt="" class=""></div>
          <h4>Réservations</h4>
          <ul>
            <li>Gestion de plusieurs points de ventes (RVC)</li>
            <li>Personnalisation et gestion du plan de table</li>
            <li>Gestion du room service</li>
            <li>Synchronisation de tous les TMS</li>
          </ul>
        </div>
        <div class="service" v-if="slide==4">
          <div class="service_img"><img src="/img/avantages/x1/groupe231.png" alt="" class=""></div>
          <h4>Outils adaptés</h4>
          <ul>
            <li>Rapport d'activité personnalisable et ajustable</li>
            <li>Gestion du budget prévisionnel</li>
          </ul>
        </div>

          </slide>
           

          <template #addons>
            <navigation />
            <pagination />
          </template>
        </carousel>


        
        
        
      </div>
      
    </div>
  </div>
</template>

<script>

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide,Pagination,Navigation } from 'vue3-carousel';
export default {
  name: 'Page_3',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data: () => ({
    slides :[1,2,3,4]
  }),
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.content_page3 .brand_page3_title{
  color: #fff;
  width: 100%;
  font-size: 100px;
  animation: content_page3_typing .5s steps(22), content_page3_blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  /* border-right: 3px solid; */
  margin-bottom: 6px;
}

.content_page3 .brand_page3_title1{
  color: #fff;
  width: 100%;
  font-size: 100px;
  
  animation: content_page3_typing 1s steps(22), content_page3_blink .5s step-end infinite alternate;
  white-space: nowrap;
  
  overflow: hidden;
  /* border-right: 3px solid; */
  margin-bottom: 6px;
}
.white_circle{
  background: #fff;
  color: #264750;
  padding: 12px;
  border-radius: 50px;
  width: 22px;
  height: 22px;
  text-align: center;
  transform: rotate(12deg);
  border: 3px solid #fff;
  position: relative;
  z-index: 9;
}
.page3_title{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
  font-weight: 600;
  font-size: 18px;
}
.right_title{
  background: #d85d36;
  color: #fff;
  padding: 12px;
  border-radius: 50px;
  width: 140px;
  text-align: center;
  transform: rotate(16deg);
  /* border: 1px solid #fff; */
  opacity: .8;
  position: relative;
  right: 3px;
  top: 12px;
}
.left_title{
  background: #264750;
  color: #fff;
  padding: 12px;
  border-radius: 50px;
  width: 90px;
  text-align: center;
  transform: rotate(-8deg);
  /* border: 1px solid #fff; */
  opacity: .8;
  position: relative;
  left: 2px;
}
.content_page3 p{
  color: #fff;
  font-size: 18px;
  margin: 0px;
  width: 70%;
  margin-top: 24px;
  
  text-align: center;
}
.content_page3{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.services{
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.service{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  margin-bottom: 24px;
  transform: scale(.4);
  opacity: 0;
  animation: content_page3_AnimationBrand .5s ease ;
  animation-fill-mode: forwards;
}
.col_service{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.col_service1 img{
    width: 50%;
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

}
.col_service1{
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service_img{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  background-color: #fff;
  justify-items: center;
  align-items: center;
  padding: 12px;
  margin-bottom: 12px;
}
.service img{
  width: 40px;
  position: relative;
  left: 10px;
}
.service h4{
  margin: 0px;
  font-size: 18px;
}
.service ul{
  list-style: none;
}
.service ul li{
  font-size: 14px;
}

.services_mobile{
    display: none !important;
  }

@media only screen and (max-width: 600px) {
  .services{
    display: none !important;
  }
  .service{
    width: calc(100% - 24px);
    padding: 12px;
  }
  .services_mobile{
    display: block !important;
    width: 80%;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }
  .service img{
    width: 40px;
    position: relative;
    left: 0px;
  }
  .service h4{
    margin-bottom: 24px;
    font-size: 18px;
  }
  
}





@keyframes myAnim {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(100px);
	}
}


@keyframes content_page3_typing {
  from {
    width: 0
    
  }
}
    
@keyframes content_page3_blink {
  50% {
    border-color: transparent
  }
}

@keyframes content_page3_AnimationBrand {
    0%{
      transform: scale(.4);
      opacity: 0;
    }
    
    100%{
      transform: scale(1);
      opacity: 1;
    }
}



@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  100% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  100% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
}



</style>
