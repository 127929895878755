<template>
  <div>
    <div class="content_page1">
      <div class="left_page1">
        <div class="brand_title">BOOK - SCAN {{title}}</div>
      <div class="brand_title1">ORDER & PAY</div>
      <p>Découvrez notre solution tout-en-un pour la gestion des réservations, commandes et paiements de votre restaurant.
        <br> 
        <br> 
        <br> 
        Simplifiez vos opérations tout en offrant à vos clients une expérience sans faille.
        <br> 
        <br> 
        <br> 
        Réservez, commandez et payez en toute facilité avec notre plateforme conviviale.
        <br> 
        <br> 
        <br> 
        Boostez vos réservations et augmentez vos ventes dès maintenant !</p>
      </div>
      <div class="right_page1">
        <img src="/img/avantclt/cellmsq.png" class="brand" alt="" srcset="">
      </div>
      
    </div>
  </div>
</template>

<script>


export default {
  name: 'Page_1',
  components:{
    
  },
  data: () => ({
  
  }),
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.content_page1 .brand_title{
  color: #fff;
  width: 100%;
  font-size: 3em;
  animation: content_page1_typing .5s steps(22), content_page1_blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  /* border-right: 3px solid; */
  margin-bottom: 6px;
}

.content_page1 .brand_title1{
  color: #fff;
  width: 100%;
  font-size: 3em;
  
  animation: content_page1_typing 1s steps(22), content_page1_blink .5s step-end infinite alternate;
  white-space: nowrap;
  
  overflow: hidden;
  /* border-right: 3px solid; */
  margin-bottom: 6px;
}


.content_page1 p{
  color: #fff;
  font-size: 18px;
  margin: 0px;
  width: 70%;
  margin-top: 24px;
}
.content_page1{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content_page1 .left_page1,.content_page1 .right_page1{
  flex: 1;
}
.content_page1 .left_page1{
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 8%;
 
}
.content_page1 .right_page1{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  overflow: hidden;
  
}
.content_page1 .brand{
  width: 70%;
  transform: rotate(-20deg);
  position: relative;
  left: 100%;
  animation: content_page1_AnimationBrand 1.5s ease ;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes content_page1_typing {
  from {
    width: 0
    
  }
}
    
@keyframes content_page1_blink {
  50% {
    border-color: transparent
  }
}

@keyframes content_page1_AnimationBrand {
    0%{
      left: 100%;
      transform: rotate(20deg);
      opacity: 0;
    }
    
    100%{
      left: 0%;
      transform: rotate(-20deg);
      opacity: 1;
    }
}

/* Responsive */

@media only screen and (max-width: 600px) {
  .content_page1{
    overflow: hidden;
  }
  .content_page1 .brand_title,.content_page1 .brand_title1{
    font-size: 28px;
    font-weight: bold;
  }
  .content_page1 p{
    font-size: 12px;
  }
  .content_page1 .right_page1{
    overflow: visible;
    
  }
  .content_page1 .brand{
  width: 100%;
  transform: rotate(-20deg);
  position: relative;
  left: 100%;
  animation: content_page1_AnimationBrand 1.5s ease ;
  animation-fill-mode: forwards;
  opacity: 0;
}
}


</style>
