<template>
  <div>
    <div class="header">
      <img src="/img/logo.png" alt="" class="logo">

      <div class="dots" @click="showMenu = true">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
    
    <div class="menu" v-if="showMenu">
        <img src="/img/close.png" alt="" @click="showMenu = false" class="close_menu">
      <div class="menu_items">
        <div class="menu_item" @click="navigate(1)" >Home</div>
        <div class="menu_item" @click="navigate(2)">About us</div>
        <div class="menu_item" @click="navigate(3)">Our services</div>
        <!-- <div class="menu_item" @click="navigate(4)">Contact us</div> -->
        <div class="menu_item" @click="navigate(4)">Our partners</div>
        <!-- <div class="menu_item" @click="navigate(6)">FAQ</div> -->
        <div class="menu_item" @click="navigate(8)">Contact us</div>
      </div>
    </div>
    <full-page ref="fullpage" @after-load="afterLoad" :options="options" id="fullpage">
      
      <div class="section">
        <Page_1 v-if="index==0"></Page_1>
      </div>
      <div class="section">
        <Page_2 v-if="index==1"></Page_2>
      </div>
      <div class="section">
        <Page_3 v-if="index==2"></Page_3>
      </div>
      <div class="section">
        <Page_4 v-if="index==3"></Page_4>
      </div>

      <div class="section">
        <Page_5 v-if="index==4"></Page_5>
      </div>      

      <div class="section">
        <Page_6 v-if="index==5"></Page_6>
      </div>      
      
      <div class="section">
        <Page_7 v-if="index==6"></Page_7>
      </div>

      <div class="section">
        <Page_8 :navigate="navigate" v-if="index==7"></Page_8>
      </div>


  </full-page>
  </div>
</template>

<script>
import Page_1 from "./components/Page1.vue"
import Page_2 from "./components/Page2.vue"
import Page_3 from "./components/Page3.vue"
import Page_4 from "./components/Page4.vue"
import Page_5 from "./components/Page5.vue"
import Page_6 from "./components/Page6.vue"
import Page_7 from "./components/Page7.vue"
import Page_8 from "./components/Page8.vue"


export default {
  name: 'App',
  components: {
    Page_1,
    Page_2,
    Page_3,
    Page_4,
    Page_5,
    Page_6,
    Page_7,
    Page_8,
   
  },
  data(){
    return{
      showMenu:false,
      index:0,
      options: {
        anchors : ["home", "aboutus", "services", "partenaires", "book", "scanorder", "pay", "contactus"]
      }
    }
  },
  computed:{
    
  },  
  watch:{
    
  },  
  mounted(){
    // console.log(this.$refs.fullpage.$data.api.getActiveSection().index())
    let $this = this;
    this.$refs.fullpage.options.afterLoad=(destination,direction)=>{
      $this.index = direction.index;
      console.log(destination)
      console.log(direction)
    }
  },
  methods:{
   navigate(index){
    this.showMenu=false;
    setTimeout(()=>{
      this.$refs.fullpage.$data.api.moveTo(index)
      console.log( this.$refs.fullpage.$data.api)
      console.log(index)
    },100)
    
   }
    
  }
}

</script>

<style>
  body{
    margin: 0px;
    
  }
  .logo{
    width: 120px;
  }
  .header{
    position: fixed;
    width: calc(100% - 48px);
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    padding-top: 24px;
    align-items: flex-start;
    z-index: 9;
  }
  .dots{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
  }
  .dot{
    width: 9px;
    height: 9px;
    background: #fff;
    border-radius: 50%;
    display: block;
    margin: 2px;
    cursor: pointer;
  }
#app {
  width:100%;
  height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  
    background: linear-gradient(144deg, #4d8999, #d85d36, #d85d36, #ffffff);
    background-size: 800% 800%;

    -webkit-animation: AnimationName 17s ease infinite;
    -moz-animation: AnimationName 17s ease infinite;
    -o-animation: AnimationName 17s ease infinite;
    animation: AnimationName 17s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 29%}
    50%{background-position:100% 72%}
    100%{background-position:0% 29%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 29%}
    50%{background-position:100% 72%}
    100%{background-position:0% 29%}
}
@-o-keyframes AnimationName {
    0%{background-position:0% 29%}
    50%{background-position:100% 72%}
    100%{background-position:0% 29%}
}

@keyframes AnimationName {
    0%{background-position:0% 29%}
    50%{background-position:100% 72%}
    100%{background-position:0% 29%}
}
@keyframes MenuAnimation {
    0%{
      transform: scale(.6);
      opacity: 0;
    }
    100%{
      transform: scale(1);
      opacity: 1;
    }
}




.close_menu{
  position: absolute;
  width: 25px;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.menu{

  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #264750;
  z-index: 99;
  opacity: 0;
  transform: scale(0);
  animation: MenuAnimation  .2s ease ;
  animation-fill-mode: forwards;
}
.menu_items{
  display: flex;
  animation: text-focus-in .4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;

}
.menu_item{
  margin: 6px;
  padding: 12px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 2px;
  
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}




/* HOVER MENU ITEM */

.menu_item:hover{
  transform: scale(1);
  animation: slide-fwd-center 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-fwd-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
@keyframes slide-fwd-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

@media only screen and (max-width: 600px) {
  .menu_items{
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}



</style>
